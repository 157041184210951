import { api } from "@/config"
import { currentTimestamp } from "@/config/store/StoreItemsModule/helpers"
import { EMPTY_TARGET_PERIOD_OBJECT } from "@/config/constants"
import { isEmpty, map, transform } from "lodash-es"
import { handleFailedResponse } from "@/helpers/common"

const EMPTY_SETTING = () => ({
  _addedAt: currentTimestamp(),
  id: undefined,
  jalan_plan_id: undefined,
  rakuten_plan_id: undefined,
  target_periods: [{ ...EMPTY_TARGET_PERIOD_OBJECT() }]
})

const prepareTargetPeriods = targetPeriods =>
  map(targetPeriods, targetPeriod => ({
    ...targetPeriod,
    start_date: targetPeriod.date_period.start_date,
    end_date: targetPeriod.date_period.end_date
  }))

const prepareByPeriodParams = ({ itemsAdded, itemsDeleted, itemsUpdated }) => {
  return transform(
    {
      update: itemsUpdated,
      delete: itemsDeleted,
      create: itemsAdded
    },

    (result, items, key) => {
      if (items.length) {
        result[key] = map(items, item => ({ ...item, target_periods: prepareTargetPeriods(item.target_periods) }))
      }
    }
  )
}

const handleSuccessResponse = (response, commit) => {
  const { items, meta } = response.data.data

  commit("SET_ITEMS", items)
  commit("SET_PAGINATION_DATA", meta.pagination)
  commit("RESET_ITEMS_DELETED")
  commit("RESET_ITEMS_ADDED")
}

export default ({ baseURI }) => ({
  async FETCH_ITEMS({ state: { filters, pagination }, commit }, customParams = {}) {
    try {
      const { headers } = customParams

      const params = {
        filters,
        pagination,
        ...customParams
      }

      const response = await api.get(baseURI, { params, headers })
      handleSuccessResponse(response, commit)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  ADD_ITEM({ commit }) {
    commit("ADD_ITEM", { item: EMPTY_SETTING() })
  },

  UPDATE_ITEMS: async (
    { state: { itemsAdded, itemsDeleted, filters, pagination }, commit, getters: { itemsUpdated } },
    customParams = {}
  ) => {
    try {
      const byPeriods = prepareByPeriodParams({ itemsAdded, itemsUpdated, itemsDeleted })

      if (isEmpty(byPeriods)) return Promise.resolve()
      const params = { filters, pagination, ...customParams }

      const response = await api.put(baseURI, { items: byPeriods, ...params })
      handleSuccessResponse(response, commit)
    } catch (e) {
      handleFailedResponse(e)
    }
  }
})
