import { find } from "lodash-es"

export default () => ({
  SET_TARGET_PERIOD_STATUSES: (state, { by_period_id, target_period_id, statuses, action }) => {
    const setting = find(state.items, { id: by_period_id })
    const targetPeriod = find(setting?.target_periods, { id: target_period_id })

    const targetPeriodIndex = setting.target_periods.indexOf(targetPeriod)
    if (targetPeriodIndex !== -1) {
      if (action === "delete") {
        setting.target_periods.splice(targetPeriodIndex, 1)
      } else {
        setting.target_periods.splice(targetPeriodIndex, 1, { ...targetPeriod, ...statuses, _statusUpdated: true })
      }

      const settingIndex = state.items.indexOf(setting)
      state.items.splice(settingIndex, 1, setting)
    }
  }
})
