<template lang="pug">
  .table-container
    .settings-by-period-table(
      v-dragscroll:nochilddrag
    )
      .table-header
        .column.header.header-dropdown(data-dragscroll)
          span {{ $t("plan_settings.settings_by_period.table_columns.car_class.header") }}
        .column.header.target-period(data-dragscroll)
          span {{ $t("plan_settings.settings_by_period.table_columns.target_period.header") }}
        .column.header(data-dragscroll)
          span {{ $t("plan_settings.settings_by_period.table_columns.plan_name.header") }}
          AppTooltip.info-icon(
            :title="$t('plan_settings.settings_by_period.table_columns.plan_name.tooltip')"
            boundary="body"
          )
        .column.header(data-dragscroll)
          span {{ $t("plan_settings.settings_by_period.table_columns.plan_name_mobile.header") }}
          AppTooltip.info-icon(
            :title="$t('plan_settings.settings_by_period.table_columns.plan_name_mobile.tooltip')"
            boundary="body"
          )
        .column.header.header-dropdown(data-dragscroll)
          span {{ $t("plan_settings.settings_by_period.table_columns.jalan_points.header") }}
        .column.header(data-dragscroll)
          span {{ $t("plan_settings.settings_by_period.table_columns.rakuten_points.header") }}
        .column.header(data-dragscroll)
          span {{ $t("plan_settings.settings_by_period.table_columns.plan_code.header") }}
        .column.header.actions(data-dragscroll)
      .table-body
        template(v-for="settingsStore in allSettings")
          SettingRow(
            v-for="(setting, index) in settingsStore"
            ref="rows"
            :key="`${setting.id.$model || setting._addedAt.$model}`"
            :index="parseInt(index)"
            :row="setting"
            :car-classes="carClasses"
            :jalan-points="jalanPoints"
            :is-car-class-disabled="isCarClassDisabled"
            :change-row="handleChangeRow(setting)"
            :delete-row="handleDeleteRow(setting)"
          )
</template>

<script>
  import { values } from "lodash-es"
  export default {
    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      SettingRow: () => import("./SettingRow")
    },

    props: {
      updateSetting: Function,
      updateAddedSetting: Function,
      deleteSetting: Function,
      deleteAddedSetting: Function,
      carClasses: {
        type: Array,
        required: true
      },
      settings: {
        type: Object,
        required: true
      },
      settingsAdded: {
        type: Object,
        required: true
      },
      jalanPoints: {
        type: Array,
        required: true
      },
      isCarClassDisabled: {
        type: Function,
        required: true
      }
    },

    computed: {
      allSettings() {
        return [values(this.settingsAdded.$each.$iter), values(this.settings.$each.$iter)]
      }
    },

    methods: {
      handleChangeRow(setting) {
        return setting._addedAt.$model ? this.updateAddedSetting : this.updateSetting
      },

      handleDeleteRow(setting) {
        return setting._addedAt.$model ? this.deleteAddedSetting : this.deleteSetting
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/tables/custom.sass"

  .table-container
    overflow-y: auto
    margin-top: 20px
    height: calc(#{$listing-height} - 90px)

  .settings-by-period-table
    display: grid
    grid-template-columns: 270px 510px 210px 345px 248px 160px 340px 55px

    .table-header, .table-body
      display: contents
      font-size: 14px
      font-weight: 400

      .app-select
      .app-select-label,
      .app-select-items,
      .app-select-search,
      .immutable
        font-size: 0.8rem
      .dropdown-toggle
        padding: 6px 10px
        width: 250px

    .column
      display: flex
      align-items: center
      height: 100%
      border-bottom: $element-border

      &.actions
        grid-column-start: 8

      &:not(.actions)
        padding: 5px 15px

      &.header
        cursor: grab
        font-size: 12px
        font-weight: 700
        padding: 7px 22px
        border-block: $element-border
        font-weight: 600
        font-size: 0.8rem
        background: $default-gray-light
        position: sticky
        top: 0
        z-index: 10

        &.header-dropdown
          padding: 7px 10px

      &.dropdown-column
        padding: 2px
        min-width: 250px
        grid-column-start: 1

        .dropdown
          width: 100%
</style>
