<template lang="pug">
  .settings-by-period
    Topbar(
      :shops="shops"
      :selected-shop="selectedShop"
      :apply-to-all-shops.sync="applyToAllShops"
      :disabled-add-new="noSelectableCarClasses"
      @select-shop="handleSelectShop"
      @add-new="handleAddRow"
      @search="handleSearch"
      @save="handleSave"
    )
    Transition(
      name="fade"
      mode="out-in"
    )
      .table-wrapper(
        v-if="anySettings"
        key="table"
      )
        AppOverlayLoader(:state="loading")
        SettingsTable(
          ref="table"
          :settings="$v.settings"
          :settings-added="$v.settingsAdded"
          :update-setting="setSettingByIndex"
          :update-added-setting="setAddedSettingByIndex"
          :delete-setting="deleteSettingByIndex"
          :delete-added-setting="deleteAddedSettingByIndex"
          :car-classes="carClasses"
          :jalan-points="jalanPoints"
          :is-car-class-disabled="isCarClassDisabled"
        )
        AppPagination(
          :current-page="pagination.current_page"
          :total="pagination.total_count",
          :per-page="pagination.per_page"
          @change-pagination-data="handlePagination"
        )
    NoData(
      v-if="!anySettings"
      key="no-data"
    )
</template>

<script>
  import { getPointsOptions } from "@/config/translations_helper"

  const SERIALIZER_VIEW = "include_basic_plans"

  // misc
  import { isEmpty, some, every, values, last } from "lodash-es"
  import SettingsTable from "./SettingsTable"
  import { appDebounce } from "@/helpers/common"

  // mixins
  import withWebSocket from "@/mixins/withWebSocket"
  import withConfirmation from "@/mixins/withConfirmation"
  import withStoreModule from "@/mixins/withStoreModule"

  // store modules
  import periodSettingsModule from "@/config/store/price_management/plan_settings/by_period"
  import shopsModule from "@/config/store/matching/shop"
  import pricesCarClassesModule from "@/config/store/price_management/car_class"

  import withValidations from "./Validations"
  import withScrollTop from "@/mixins/withScrollTop"

  const periodSettingsMixin = withStoreModule(periodSettingsModule, {
    resetState: true,
    name: "periodSettings",
    readers: {
      loading: "loading",
      settings: "items",
      settingsAdded: "itemsAdded",
      settingsDeleted: "itemsDeleted",
      pagination: "pagination"
    },
    actions: {
      addSetting: "ADD_ITEM",
      fetchPeriodSettings: "FETCH_ITEMS",
      updatePeriodSettings: "UPDATE_ITEMS"
    },
    mutations: {
      applyFilters: "SET_FILTERS",
      setSettingByIndex: "SET_ITEM_BY_INDEX",
      setAddedSettingByIndex: "SET_ADDED_ITEM_BY_INDEX",
      setTargetPeriodStatuses: "SET_TARGET_PERIOD_STATUSES",
      deleteAddedSettingByIndex: "DELETE_ADDED_ITEM_BY_INDEX",
      deleteSettingByIndex: "DELETE_ITEM_BY_INDEX",
      setPagination: "SET_PAGINATION_DATA",
      setInvalidSettingIds: "SET_INVALID_ITEM_IDS",
      resetAddedItems: "RESET_ITEMS_ADDED",
      resetDeletedItems: "RESET_ITEMS_DELETED",
      resetItems: "RESET_ITEMS"
    },
    getters: { settingsUpdated: "itemsUpdated" }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    resetState: true,
    name: "shopMatching",
    readers: { shopsLoading: "loading", shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const pricesCarClassesMixin = withStoreModule(pricesCarClassesModule, {
    resetState: true,
    name: "byPeriodCarClasses",
    readers: { carClassesLoading: "loading", carClasses: "items" },
    actions: { fetchCarClassesAction: "FETCH_ITEMS" }
  })

  export default {
    mixins: [
      withConfirmation,
      periodSettingsMixin,
      shopsMixin,
      pricesCarClassesMixin,
      withValidations,
      withScrollTop,
      withWebSocket
    ],

    components: {
      SettingsTable,
      Topbar: () => import("./Topbar.vue"),
      NoData: () => import("@/components/NoData"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({ to, next, isChanges: this.hasUnsavedChanges })
    },

    watch: {
      hasUnsavedChanges(useConfirm) {
        this.setLogoutConfirm(useConfirm)
      }
    },

    data() {
      return {
        selectedShop: {},
        applyToAllShops: false
      }
    },

    mounted() {
      this.fetchShops({
        pagination: { _disabled: true },
        matched_only: true,
        synced_only: true,
        price_plans_matched_only: true
      })
      this.webSocketSubscribe("ByPeriodChannel", { received: this.setTargetPeriodStatuses })
    },

    computed: {
      jalanPoints() {
        return getPointsOptions(this.$i18n)
      },

      hasUnsavedChanges({ settingsUpdated }) {
        return (
          !isEmpty(this.$v.settingsAdded.$model) ||
          !isEmpty(settingsUpdated) ||
          !isEmpty(this.$v.settingsDeleted.$model)
        )
      },

      noSelectableCarClasses() {
        return every(this.carClasses, carClass => this.isCarClassDisabled(carClass))
      },

      anySettings() {
        return this.$v.settings.$model?.length || this.$v.settingsAdded.$model?.length
      }
    },

    methods: {
      isEmpty,

      debouncedFetchPeriodSettings: appDebounce(function() {
        this.fetchPeriodSettings()
      }),

      async fetchCarClasses() {
        await this.fetchCarClassesAction({
          pagination: { _disabled: true },
          include_basic_plans: true,
          having_basic_plans: true,
          shop_id: this.selectedShop.id,
          serializer_view: SERIALIZER_VIEW
        })
      },

      isCarClassDisabled(carClass) {
        return this.isSettingMatched(carClass) || this.isAddedSettingMatched(carClass)
      },

      isSettingMatched(carClass) {
        return this.checkMatching(this.settings, carClass)
      },

      isAddedSettingMatched(carClass) {
        return this.checkMatching(this.settingsAdded, carClass)
      },

      checkMatching(settings, { jalan_plan_id, rakuten_plan_id }) {
        return some(
          settings,
          setting =>
            (jalan_plan_id && jalan_plan_id === setting.jalan_plan_id) ||
            (rakuten_plan_id && rakuten_plan_id === setting.rakuten_plan_id)
        )
      },

      handleSearch(value) {
        this.applyFilters({ search_value: value })
        this.debouncedFetchPeriodSettings()
      },

      async handleSave() {
        this.$v.$touch()

        if (this.$v.$invalid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }

        await this.updatePeriodSettings()
        await this.fetchCarClasses()
      },

      async handleSelectShop(shop) {
        this.$conditionalConfirm({
          useConfirm: this.hasUnsavedChanges,
          handler: async () => {
            this.selectedShop = shop
            this.applyFilters({ shop_id: this.selectedShop.id })
            this.resetChanges()
            await this.fetchCarClasses()
            this.fetchPeriodSettings()
          }
        })
      },

      resetChanges() {
        this.resetItems()
        this.resetAddedItems()
        this.resetDeletedItems()
      },

      handleAddRow() {
        this.addSetting()
        last(values(this.$v.settingsAdded.$each.$iter)).$reset()
      },

      handlePagination(pagination) {
        this.$conditionalConfirm({
          useConfirm: this.hasUnsavedChanges,
          handler: () => {
            this.setPagination({ ...this.pagination, ...pagination })
            this.resetChanges()
            this.fetchPeriodSettings()
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  .settings-by-period
    .table-wrapper
      position: relative

    ::v-deep
      .app-pagination
        .pager
          margin-right: 0

        .per-page
          padding-left: 10px
          padding-right: 0

          button
            height: 36px
</style>
