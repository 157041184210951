import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

const BASE_URI = "/plan_settings/by_periods"

import extractActions from "./actions"
import extractMutations from "./mutations"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: {
    shop_id: null,
    search_value: ""
  },
  withPagination: true
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI: BASE_URI }), withLoading)

export default store
