import { validationMixin } from "vuelidate"
import { required, minValue, requiredIf } from "vuelidate/lib/validators"
import { parse, subYears, startOfDay } from "date-fns"
import { cloneDeep } from "lodash-es"
import { currentDateInTimeZone } from "@/helpers/common"

function createDate({ year, month, day }) {
  return new Date(year, month - 1, day)
}

export function dateOverlaps(period1, period2) {
  const startDate1 = createDate(period1.start_date)
  const endDate1 = createDate(period1.end_date)
  const startDate2 = createDate(period2.start_date)
  const endDate2 = createDate(period2.end_date)

  return (
    (startDate1 <= startDate2 && startDate2 <= endDate1) ||
    (startDate1 <= endDate2 && endDate2 <= endDate1) ||
    (startDate2 < startDate1 && startDate1 < endDate2)
  )
}

const validation = {
  $each: {
    _addedAt: { required: false },
    _updated: { required: false },
    id: { required: false },
    jalan_plan_id: {
      required: requiredIf(function(nested) {
        return !nested.rakuten_plan_id
      })
    },
    rakuten_plan_id: {
      required: requiredIf(function(nested) {
        return !nested.jalan_plan_id
      })
    },
    target_periods: {
      $each: {
        _statusUpdated: { required: false },
        _addedAt: { required: false },
        _jalan_plan_id: { required: false },
        _rakuten_plan_id: { required: false },
        id: { required: false },
        plan_name: { required },
        plan_name_mobile: { required: false },
        jalan_points: {
          required: requiredIf(function(nested) {
            return !!nested._jalan_plan_id
          })
        },
        rakuten_points: {
          required: requiredIf(function(nested) {
            return !!nested._rakuten_plan_id
          })
        },
        plan_code: { required: false },
        date_period: {
          isValid: function(nested) {
            const { start_date: start, end_date: end } = nested
            const startDate = parse(`${start.year}-${start.month}-${start.day}`, "yyyy-M-d", new Date())
            const endDate = parse(`${end.year}-${end.month}-${end.day}`, "yyyy-M-d", new Date())
            const limitStartDate = startOfDay(subYears(currentDateInTimeZone(), 5))
            return limitStartDate <= startDate && startDate <= endDate
          },
          start_date: {
            year: { required, minValue: minValue(1) },
            month: { required, minValue: minValue(1) },
            day: { required, minValue: minValue(1) }
          },
          end_date: {
            year: { required, minValue: minValue(1) },
            month: { required, minValue: minValue(1) },
            day: { required, minValue: minValue(1) }
          }
        }
      }
    }
  }
}

export default {
  mixins: [validationMixin],

  validations() {
    return {
      settings: cloneDeep(validation),
      settingsAdded: cloneDeep(validation),
      settingsDeleted: cloneDeep(validation)
    }
  }
}
